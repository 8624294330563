$(document).ready(function () {
  ("use strict");

  // Menú: afegim botó i ocultem menú quan hi ha js
  // $("#menu")
  //   .before(
  //     '<button class="hamburger hamburger--spring" type="button" aria-expanded="false" aria-controls="menu"><span class="hamburger-box"><span class="hamburger-inner"></span></span><span class="is-sr-only">Menu</span></button>'
  //   )
  //   .attr("hidden", "true");

  // ----
  // menú (https://polypane.rocks/blog/the-perfect-responsive-menu-2019/)
  // ----
  // var toggleMenu = document.querySelector(".navigation button");
  // var menu = document.querySelector("#menu");

  // if (toggleMenu) {
  //   toggleMenu.addEventListener("click", function () {
  //     var open = JSON.parse(toggleMenu.getAttribute("aria-expanded"));
  //     toggleMenu.setAttribute("aria-expanded", !open);
  //     menu.hidden = !menu.hidden;
  //   });
  // }

  // $(".hamburger").on("click", function () {
  //   // afegir classe al botó
  //   $(this).toggleClass("is-active");
  //   // afegir classe al body
  //   // $("body").toggleClass("js-menu-open");
  // });

  // -------------------------------
  // Menú principal amb desplegables (https://www.w3.org/WAI/tutorials/menus/flyout/#use-button-as-toggle)
  // -------------------------------

  // var menuItems = document.querySelectorAll("#menu li.has-submenu");
  // Array.prototype.forEach.call(menuItems, function (el, i) {
  //   var activatingA = el.querySelector("a");
  //   var btn =
  //     '<button><span><span class="is-sr-only">Mostra el submenú per “' +
  //     activatingA.text +
  //     "”</span></span></button>";
  //   activatingA.insertAdjacentHTML("afterend", btn);
  //   // activatingA.setAttribute("aria-expanded", "false");

  //   el.querySelector("button").addEventListener("click", function (event) {
  //     var currentMenu = this.parentNode;
  //     Array.prototype.forEach.call(menuItems, function (el, i) {
  //       if (currentMenu !== el) {
  //         el.classList.remove("open");
  //         el.classList.add("close");
  //         //el.querySelector("a").setAttribute("aria-expanded", "false");
  //         el.querySelector("button").setAttribute("aria-expanded", "false");
  //       }
  //     });
  //     //console.log(this.parentNode.className);
  //     if (this.parentNode.classList.contains("close")) {
  //       this.parentNode.classList.add("open");
  //       this.parentNode.classList.remove("close");
  //       /*this.parentNode
  //         .querySelector("a")
  //         .setAttribute("aria-expanded", "true");*/
  //       this.parentNode
  //         .querySelector("button")
  //         .setAttribute("aria-expanded", "true");
  //     } else {
  //       this.parentNode.classList.add("close");
  //       this.parentNode.classList.remove("open");
  //       /*this.parentNode
  //         .querySelector("a")
  //         .setAttribute("aria-expanded", "false");*/
  //       this.parentNode
  //         .querySelector("button")
  //         .setAttribute("aria-expanded", "false");
  //     }
  //     event.preventDefault();
  //   });
  // });

  // ---------
  // skip link (https://www.bignerdranch.com/blog/web-accessibility-skip-navigation-links/)
  // ---------
  // bind a click event to the 'skip' link
  $(".skip-link").click(function (event) {
    // strip the leading hash and declare
    // the content we're skipping to
    var skipTo = "#" + this.href.split("#")[1];

    // Setting 'tabindex' to -1 takes an element out of normal
    // tab flow but allows it to be focused via javascript
    $(skipTo)
      .attr("tabindex", -1)
      .on("blur focusout", function () {
        // when focus leaves this element,
        // remove the tabindex attribute
        $(this).removeAttr("tabindex");
      })
      .focus(); // focus on the content container
  });

  // --------
  // Pagament
  // --------
  $(document).on('change','.pagament input.is-sr-only', function(){
    if($(this).val() == 'Compte corrent'){
      $('.pagament .p-5 input').attr('required', 'required');
      $('.pagament .p-5 label').append('<span class="required">*</span>');
    } else {
      $('.pagament .p-5 input').attr('required', false);
      $('.pagament .p-5 label span').remove();
    }
  });

  // -----
  // Alert
  // -----
  $(".alert button").on("click", function () {
    $(this).parent().hide();
  });

  // ----------
  // scroll top
  // ----------
  $(window).scroll(function () {
    if ($(this).scrollTop() > 400) {
      $(".scrolltop").addClass("visible");
    } else {
      $(".scrolltop").removeClass("visible");
    }
  });

  // --------
  // headroom
  // --------
  // $(".main-header").headroom(
    // {
    // offset: 0,
    // tolerance: 5,
    // classes: {
    //   initial: "animated",
    //   pinned: "slideDown",
    //   unpinned: "slideUp"
    // }
  // }
  // );

  // -----------
  // File upload
  // -----------
  // $('input[type="file"]').change(function () {
  //   var i = $(this).next().find(".file-label").clone();
  //   var file = $('input[type="file"]')[0].files[0].name;
  //   $(this).next().find(".file-label").text(file);
  // });

  // ----
  // Tabs https://inclusive-components.design/tabbed-interfaces/
  // ----
  // if ($(".tabbed").length > 0) {
  //   $(".tabbed").each(function (index, element) {
  //     // Get relevant elements and collections
  //     var tabbed = element;
  //     var tablist = tabbed.querySelector("ul.tab-menu");
  //     var tabs = tablist.querySelectorAll("a");
  //     var panels = tabbed.querySelectorAll(".tab-panel");

  //     // The tab switching function
  //     var switchTab = function switchTab(oldTab, newTab) {
  //       newTab.focus(); // Make the active tab focusable by the user (Tab key)

  //       newTab.removeAttribute("tabindex"); // Set the selected state

  //       newTab.setAttribute("aria-selected", "true");
  //       oldTab.removeAttribute("aria-selected");
  //       oldTab.setAttribute("tabindex", "-1"); // Get the indices of the new and old tabs to find the correct
  //       // tab panels to show and hide

  //       var index = Array.prototype.indexOf.call(tabs, newTab);
  //       var oldIndex = Array.prototype.indexOf.call(tabs, oldTab);
  //       panels[oldIndex].hidden = true;
  //       panels[index].hidden = false;
  //     }; // Add the tablist role to the first <ul> in the .tabbed container

  //     tablist.setAttribute("role", "tablist"); // Add semantics are remove user focusability for each tab

  //     Array.prototype.forEach.call(tabs, function (tab, i) {
  //       tab.setAttribute("role", "tab");
  //       //	    tab.setAttribute("id", "tab" + (i + 1));
  //       tab.setAttribute("tabindex", "-1");
  //       tab.parentNode.setAttribute("role", "presentation"); // Handle clicking of tabs for mouse users

  //       tab.addEventListener("click", function (e) {
  //         e.preventDefault();
  //         var currentTab = tablist.querySelector("[aria-selected]");

  //         if (e.currentTarget !== currentTab) {
  //           switchTab(currentTab, e.currentTarget);
  //         }
  //       }); // Handle keydown events for keyboard users

  //       tab.addEventListener("keydown", function (e) {
  //         // Get the index of the current tab in the tabs node list
  //         var index = Array.prototype.indexOf.call(tabs, e.currentTarget); // Work out which key the user is pressing and
  //         // Calculate the new tab's index where appropriate

  //         var dir =
  //           e.which === 37
  //             ? index - 1
  //             : e.which === 39
  //               ? index + 1
  //               : e.which === 40
  //                 ? "down"
  //                 : null;

  //         if (dir !== null) {
  //           e.preventDefault(); // If the down key is pressed, move focus to the open panel,
  //           // otherwise switch to the adjacent tab

  //           dir === "down"
  //             ? panels[i].focus()
  //             : tabs[dir]
  //               ? switchTab(e.currentTarget, tabs[dir])
  //               : void 0;
  //         }
  //       });
  //     }); // Add tab panel semantics and hide them all

  //     Array.prototype.forEach.call(panels, function (panel, i) {
  //       panel.setAttribute("role", "tabpanel");
  //       panel.setAttribute("tabindex", "-1");
  //       var id = panel.getAttribute("id");
  //       panel.setAttribute("aria-labelledby", tabs[i].id);
  //       panel.hidden = true;
  //     }); // Initially activate the first tab and reveal the first tab panel

  //     tabs[0].removeAttribute("tabindex");
  //     tabs[0].setAttribute("aria-selected", "true");
  //     panels[0].hidden = false;
  //   });
  // }

  // ----
  // Form
  // ----
  // $('#aportacio-altra').on('input', function() {
  //   if ($(this).val().trim() !== '') {
  //     $('input[type="radio"][id^="aportacio"]').prop('disabled', true);
  //     $('input[type="radio"][id^="aportacio"]:disabled').prop('checked', false);
  //   } else {
  //     $('input[type="radio"][id^="aportacio"]').prop('disabled', false);
  //   }
  // });

  // ------
  // Pop-up
  // ------
  $('.popup').magnificPopup({
		type: 'inline',
    closeMarkup: '<button type="button" class="mfp-close" aria-label="Tancar"><img src="/media/img/ico-tancar-pop-up.svg" alt="" width="60" height="60" class="mfp-close"></button>',
    tClose: 'Tancar (Esc)',
    tLoading: 'Carregant…',
  });

  $('.popup-img').magnificPopup({
    type: 'image',
    closeMarkup: '<button type="button" class="mfp-close" aria-label="Tancar"><img src="/media/img/ico-tancar-pop-up.svg" alt="" width="60" height="60" class="mfp-close"></button>',
    tClose: 'Tancar (Esc)',
    tLoading: 'Carregant…',
    image: {
      // markup: '<div class="mfp-figure">'+
      //       '<div class="mfp-close"></div>'+
      //       '<div class="mfp-img"></div>'+
      // '</div>',
      cursor: null,
      verticalFit: false,
      tError: '<a href="%url%">La imatge</a> no s\'ha pogut carregar.'
    }
	});

});

// ------
// Swiper
// ------
const swiper = new Swiper('.swiper', {
  // Optional parameters
  loop: true,

  // Customize the bullets for pagination
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
    effect: 'slide',
    loop: true,
    renderBullet: function (index, className) {
          // You can change the SVG content for each bullet as per your need
          return '<span class="' + className + '">' + svgBullets[index] + '</span>';
    }
  },
  // Enable autoplay
  autoplay: {
      delay: 5000,  // Time in milliseconds between slides
      disableOnInteraction: true, // When set to false, autoplay will not be disabled after user interactions (swipes), which is often the desired behavior.
  },

});



$(".share-button").click((function(){var e=window.location.href;navigator.clipboard.writeText(e).then((function(){$(".tooltip").removeClass("hidden"),navigator.share&&navigator.share({url:e}).then((function(){})).catch((function(e){console.error("Error sharing URL:",e)}))})).catch((function(e){console.error("Error copying URL to clipboard:",e)}))}))

// -----------
// Collapsible (https://inclusive-components.design/collapsible-sections/)
// -----------
// (function () {
//   // Get all the headings
//   const headings = document.querySelectorAll('.collapsible__title')

//   Array.prototype.forEach.call(headings, heading => {
//     // Give each <h2> a toggle button child
//     // with the SVG plus/minus icon
//     heading.innerHTML = `
//       <button aria-expanded="false" class="is-flex is-justify-content-space-between is-align-items-center p-2">
//         ${heading.textContent}
//         <svg class="icon is-flex-shrink-0" aria-hidden="true" focusable="false">
//             <use xlink:href="/media/img/svg-symbols.svg#angle-down"></use>
//         </svg>
//       </button>
//     `
//     // Function to create a node list
//     // of the content between this heading and the next
//     const getContent = (elem) => {
//       let elems = []
//       while (elem.nextElementSibling && elem.nextElementSibling.className !== 'collapsible__title') {
//         elems.push(elem.nextElementSibling)
//         elem = elem.nextElementSibling
//       }

//       // Delete the old versions of the content nodes
//       elems.forEach((node) => {
//         node.parentNode.removeChild(node)
//       })

//       return elems
//     }

//     // Assign the contents to be expanded/collapsed (array)
//     let contents = getContent(heading)

//     // Create a wrapper element for `contents` and hide it
//     let wrapper = document.createElement('div')
//     wrapper.hidden = true

//     // Add each element of `contents` to `wrapper`
//     contents.forEach(node => {
//       wrapper.appendChild(node)
//     })

//     // Add the wrapped content back into the DOM
//     // after the heading
//     heading.parentNode.insertBefore(wrapper, heading.nextElementSibling)

//     // Assign the button
//     let btn = heading.querySelector('button')

//     btn.onclick = () => {
//       // Cast the state as a boolean
//       let expanded = btn.getAttribute('aria-expanded') === 'true' || false

//       // Switch the state
//       btn.setAttribute('aria-expanded', !expanded)
//       // Switch the content's visibility
//       wrapper.hidden = expanded
//     }
//   })

//   if ($('.collapsible__title').length > 0 && window.location.hash.indexOf('#entry-') > -1 && $(window.location.hash).length > 0) {
//     $(window.location.hash).find('button').attr('aria-expanded', true);
//     $(window.location.hash).next().show();
//     $('html, body').animate({
//         scrollTop: $(window.location.hash).parent().offset().top
//     }, 1000);
//   }

// })();
